import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { Compliance } from "../Compliance/Compliance";
import { DownloadAppBadges } from "../DownloadAppBadges";
import { RightsReserved } from "../Footer/RightsResevered";
import { SocialLinks } from "../SocialLinks";
import Cookies from "js-cookie";
import { COOKIE_CONCENT_COOKIE_NAME } from "../../lib/useCookiesConsent";
const Newsletter = dynamic(() => import("../Newsletter"));
interface FooterItem {
  id: string;
  href: string;
  title: string;
  action?: () => void;
  openInNewTab?: boolean;
}
interface FooterSection {
  title: string;
  className?: string;
  items: FooterItem[];
}
const FooterItems: FooterSection[] = [{
  title: "Solutions",
  items: [{
    id: "employers-footer",
    href: "/employers",
    title: "Employers & HR"
  }, {
    id: "consultants-footer",
    href: "/consultants",
    title: "Consultants"
  }, {
    id: "life-insurance-footer",
    href: "/life-insurance",
    title: "Life Insurance Carriers"
  }, {
    id: "health-plans-footer",
    href: "/healthplans",
    title: "Health Plans"
  }, {
    id: "families-footer",
    href: "/families",
    title: "Families"
  }, {
    id: "product-footer",
    href: "/product",
    title: "Loss Support"
  }, {
    id: "lifevault-footer",
    href: "/lifevault",
    title: "Legacy Planning"
  }]
}, {
  title: "Company",
  items: [{
    id: "about-footer",
    href: "/about",
    title: "About"
  }, {
    id: "careers__join_the_team_footer",
    href: "/careers",
    title: "Careers"
  }, {
    id: "news-room-footer",
    href: "/news-room",
    title: "Press"
  }, {
    id: "privacy-footer",
    href: "/legal/privacy",
    title: "Privacy Policy"
  }, {
    id: "legal-footer",
    href: "/legal/terms",
    title: "Terms of Service"
  }, {
    id: "your_privacy_choices",
    href: "/legal/cookie-policy",
    title: "Your Privacy Choices",
    action: () => {
      Cookies.remove(COOKIE_CONCENT_COOKIE_NAME);
    }
  }, {
    id: "cookies-footer",
    href: "/legal/cookie-policy",
    title: "Cookie Policy"
  }, {
    id: "contact-us-footer",
    href: "mailto:hello@empathy.com",
    title: "Contact Us",
    openInNewTab: true
  }]
}, {
  title: "Resources",
  className: "row-span-2 md:row-span-1",
  items: [{
    id: "learn-footer",
    href: "/learn",
    title: "Knowledge Base"
  }, {
    id: "employer-resources-footer",
    href: "/employer-resources",
    title: "Employer Resources"
  }, {
    id: "costofdying-footer",
    href: "/costofdying",
    title: "Cost of Dying"
  }, {
    id: "stories-footer",
    href: "/stories",
    title: "Care Stories"
  }, {
    id: "resources-for-families-footer",
    href: "/resources-for-families",
    title: "Anticipatory Loss"
  }, {
    id: "faq__footer_button",
    href: "/faq",
    title: "FAQ"
  }]
}];
interface Props {
  openLinksInNewWindow?: boolean;
}
const openInNewTab = {
  target: "_blank",
  rel: "noopener noreferrer"
};
export function Footer({
  openLinksInNewWindow = false
}: Props) {
  const router = useRouter();
  const onMobile = router.query.mobile;
  if (onMobile) {
    return null;
  }
  return <footer role="contentinfo" className="relative bg-white" aria-labelledby="footerHeading" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div id="footerHeading" className="sr-only">
        Footer
      </div>
      <div className="mx-auto max-w-7xl py-16 px-8 lg:px-10 lg:py-16 ">
        <div className="flex grid-flow-col flex-col md:grid md:gap-8">
          <div className="col-span-2 grid grid-flow-col grid-cols-2 gap-y-10 gap-x-10 lg:grid-cols-3 ">
            {FooterItems.map(section => <div key={section.title} className={section.className}>
                <h3 className="text-h4 md:text-h3">{section.title}</h3>
                <ul className="mt-4 space-y-3 md:space-y-4">
                  {section.items.map(item => <li key={item.id}>
                      <FooterLink item={item} openLinksInNewWindow={openLinksInNewWindow} />
                    </li>)}
                </ul>
              </div>)}
          </div>
          <hr className="my-12 border-plum-16 md:hidden" />
          <div className="flex flex-col items-center md:items-end xl:flex-row xl:items-start xl:justify-end">
            <Newsletter data-sentry-element="Newsletter" data-sentry-source-file="Footer.tsx" />
            <SocialLinks data-sentry-element="SocialLinks" data-sentry-source-file="Footer.tsx" />
          </div>
        </div>

        <div className="-mx-8 -mb-16 mt-10 w-screen bg-plum-8 px-8 py-8 pb-16 md:m-0 md:w-auto md:bg-transparent md:p-0">
          <div className="flex-row md:mt-14 md:flex md:items-end md:justify-between">
            <div className="flex flex-col gap-4">
              <Compliance className="opacity-[64%]" data-sentry-element="Compliance" data-sentry-source-file="Footer.tsx" />
              <p className="text-body-small mb-8 max-w-lg text-plum-64 md:mb-0 lg:max-w-2xl">
                Empathy is for everyone, because loss affects everyone. We’re
                proud to support every person and family, regardless of their
                age, race, religion, ethnicity, gender identity, or sexual
                orientation.
              </p>
            </div>
            <DownloadAppBadges data-sentry-element="DownloadAppBadges" data-sentry-source-file="Footer.tsx" />
          </div>
          <RightsReserved data-sentry-element="RightsReserved" data-sentry-source-file="Footer.tsx" />
        </div>
      </div>
    </footer>;
}
const FooterLink = ({
  item,
  openLinksInNewWindow
}: {
  item: FooterItem;
  openLinksInNewWindow: boolean;
}) => {
  const click = ev => {
    if (!item.action) {
      return;
    }
    ev.preventDefault();
    item.action();
    window.location.href = item.href;
  };
  return <a href={item.href} className="text-body-small md:text-body-standard text-plum-80 hover:text-plum" id={item.id} {...item.openInNewTab || openLinksInNewWindow ? openInNewTab : {}} onClick={click} data-sentry-component="FooterLink" data-sentry-source-file="Footer.tsx">
      {item.title}
    </a>;
};