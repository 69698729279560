import React from "react";
import CookieConsent from "react-cookie-consent";
import { useCookiesConsent } from "../lib/useCookiesConsent";
export const CookiesConsent = ({
  forceOpen = false
}) => {
  const {
    isGdprCountry
  } = useCookiesConsent();
  if (!isGdprCountry && !forceOpen) {
    return null;
  }
  return <CookieConsent enableDeclineButton onAccept={() => {
    window.location.reload();
  }} containerClasses="!bg-white !text-plum !py-4 !px-8  border-t border-plum-16 !items-center " buttonClasses={"h-10 px-8 text-body-small rounded-full text-beige-64 bg-plum hover:bg-plum-80 bg-plum "} declineButtonClasses={"h-10 px-8 text-body-small rounded-full text-plum border-plum border hover:bg-plum-8 active:bg-plum-16"} disableButtonStyles={true} visible={forceOpen ? "show" : undefined} buttonText="Accept" declineButtonText="Decline" flipButtons={true} contentClasses="max-w-6xl !mx-0 !mt-2 !mb-6 text-center md:text-left md:!m-2 text-body-small " buttonWrapperClasses="flex flex-1 flex-row justify-center md:justify-end gap-2" data-sentry-element="CookieConsent" data-sentry-component="CookiesConsent" data-sentry-source-file="CookiesConsent.tsx">
      We use cookies to improve your experience on our site, functionality,
      performance, ads personalization, and analyzing traffic. Some of these may
      constitute “sharing” or “processing” for purposes of “targeted
      advertising” as defined under US privacy laws. You may opt out of these
      cookies by clicking on “Decline“. You can change your choice by clicking
      on “Your Privacy Choices“, which will reopen this banner. See our{" "}
      <a className="underline" href="/legal/cookie-policy">
        {"Cookie Policy"}
      </a>{" "}
      for further information.
    </CookieConsent>;
};