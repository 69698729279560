import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import Image from "next/legacy/image";
import React from "react";
import { Logo } from "./types";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
interface Props {
  logo: Logo;
  variant: keyof typeof StyleVariants;
  className?: string;
  logoColors?: "grayscale" | "colors";
  height?: number;
}
export const LogoItem: React.FC<Props> = ({
  variant,
  className,
  logo,
  logoColors = "grayscale",
  height = 120,
  ...props
}) => {
  return <div {...props} className={clsx("group flex items-center justify-center self-center", StyleVariants[variant], className)} data-sentry-component="LogoItem" data-sentry-source-file="LogoItem.tsx">
      <Image {...toFieldPath(".image")} className={clsx({
      "rounded grayscale opacity-60 transition-all group-hover:opacity-100 group-hover:grayscale-0": logoColors !== "colors"
    })} src={imageUrlFor(logo.image)} alt={logo.name} width={180} height={height} quality={100} objectFit="contain" data-sentry-element="Image" data-sentry-source-file="LogoItem.tsx" />
    </div>;
};
const StyleVariants = {
  transparent: "",
  beige: "bg-beige rounded-lg md:py-4",
  beige64: "bg-beige-64 rounded-lg md:py-4",
  plum4: "bg-plum-4 rounded-lg md:py-4",
  white: "bg-white rounded-lg md:py-4"
};